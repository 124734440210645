import './App.css';
// import MainNav from './components/navbar';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Landing from "./pages/landing";
import Video from "./pages/video-showcase";
import Drone from "./pages/drone-showcase";
import Blog from "./pages/blog";
import Contact from "./pages/contact";

function App() {
  return (
    <div className="App">
      {/* <MainNav></MainNav> */}
      <BrowserRouter>
        <Routes>
          <Route index element={<Landing></Landing>}></Route>
          <Route path="blog" element={<Blog></Blog>}></Route>
          <Route path="contact" element={<Contact></Contact>}></Route>
          <Route path="drone-projects" element={<Drone></Drone>}></Route>
          <Route path="video-projects" element={<Video></Video>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
