import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import WhiteLogo from "../img/logo/logo-05.png";
import Slide from "@mui/material/Slide";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import CameraIcon from "@mui/icons-material/CameraAlt";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { routes } from "../routes/page-routes";
import { ThemeProvider } from "@mui/material";
import "../App.css";

function Homepage() {
  const [logoHovered, setLogoHovered] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [panel1Open, setPanel1Open] = useState(false);
  const [panel2Open, setPanel2Open] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLogo(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="homepage">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="nav-bar">
          <Toolbar>
            <Typography variant="h6" component="div">
              <div
                className="logo"
                onMouseEnter={() => setLogoHovered(true)}
                onMouseLeave={() => setLogoHovered(false)}
              >
                <img
                  src={logoHovered ? WhiteLogo : WhiteLogo}
                  alt="Company Logo"
                />
              </div>
            </Typography>
            <Typography sx={{ flexGrow: 1 }}></Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <a 
                href="tel:5551234567" 
                style={{ 
                  color: 'inherit', 
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <PhoneIcon fontSize="medium" className="phone-icon" />
                <Typography 
                  variant="body1" 
                  sx={{ 
                    display: { xs: 'none', sm: 'block' }
                  }}
                >
                  (555) 123-4567
                </Typography>
              </a>
              <EmailIcon fontSize="medium" className="mail-icon" />
              <MenuIcon
                fontSize="large"
                className="menu-icon"
                onClick={() => setDrawerOpen(true)}
              />
            </Box>
          </Toolbar>
          <Drawer
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'rgba(39, 50, 53, 0.575) !important',
              },
              '& .MuiPaper-root': {
                backgroundColor: 'rgb(0,0,0)',
                fontWeight: '600',
                color: 'rgb(233,30,99)',
                width: '200px'
              }
            }}
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            className="menu-backdrop"
          >
            <div className="drawer-top">
              <img src={WhiteLogo} alt="Company Logo" />
            </div>
            <List>
              {routes.map((route) => (
                <ListItem
                  button
                  component={Link}
                  to={route.route}
                  key={route.route}
                >
                  {/* <route.icon /> */}
                  <ListItemText
                    disableTypography
                    primary={route.label}
                    sx={{ 
                      fontFamily: "Karma",
                      '&:hover': {
                        color: 'white'
                      },
                      fontSize: "20px"}}
                  />
                </ListItem>
              ))}
            </List>
            <div className="drawer-bottom">
              <FacebookIcon fontSize="medium" />
              <InstagramIcon fontSize="medium" />
              <TwitterIcon fontSize="medium" />
              <LinkedInIcon fontSize="medium" />
            </div>
          </Drawer>
        </AppBar>
      </Box>

      <div className="hero">
        <CSSTransition in={showLogo} timeout={1000} classNames="logo-fade">
          <div className={`main-logo ${showLogo && "fade-in"}`} />
        </CSSTransition>
      </div>

      {!panel1Open && (
        <div
          className="hover-arrow"
          onClick={panel1Open ? setPanel2Open : setPanel1Open}
        >
          <FontAwesomeIcon icon={panel1Open ? faChevronDown : faChevronUp} />
        </div>
      )}

      <Slide direction="up" in={panel1Open}>
        <div className="panel1">
          <div className="arrow-up" onClick={() => setPanel1Open(false)}>
            {/* <FontAwesomeIcon icon={faChevronUp} /> */}
            <HomeIcon />
          </div>

          <div>PANEL 1</div>

          <div className="hover-arrow" onClick={() => setPanel2Open(true)}>
            {/* <FontAwesomeIcon icon={faChevronDown} /> */}
            <CameraIcon />
          </div>
        </div>
      </Slide>

      <Slide direction="up" in={panel2Open}>
        <div className="panel2">
          {/* Panel 2 */}
          <div>PANEL 2</div>

          <div className="arrow-up" onClick={() => setPanel2Open(false)}>
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
        </div>
      </Slide>
    </div>
  );
}

export default Homepage;